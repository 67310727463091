import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import Brands from "../components/base/Brands";
import WhyChooseUs from "../components/base/WhyChooseUs";
import Testimonial from "../components/base/Testimonial"
import CustomFooter from "../components/CustomFooter";

export const WebDynamicPenetrationTestingPageTemplate = ({
  seo,
  hero,
  contactus,
  wpptsection,
  dastsection,
  mpsection,
  rvsection,
  rrsection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} titlePostfix="%s" article_section={seo.article_section} blogservice={true} />
      <HeroOther data={hero} />
      <section className="section custom-section">
        <div className="container" style={{ textAlign: 'center' }}>
          <SubTitle title={contactus.title} style={{ fontSize: '1.2rem' }} />
          <div style={{ marginTop: '1rem' }}>
            <a href={contactus.buttonlink}>
              <button className="btn-light">{contactus.buttontxt} &#10230;</button>
            </a>
          </div>
        </div>
      </section>
      <section className="section wpptsection custom-section" style={{ background: `${wpptsection.sectionbg}` }}>
        <div className="container">
          <Descriptions descriptions={wpptsection.descriptions} color={wpptsection.textcolor} margin="0 0 1rem" />
          <a href={contactus.buttonlink}>
            <button className="btn-light">Leverage Our Penetration Testing Services &#10230;</button>
          </a>
        </div>
      </section>
      <section className="section dastsection custom-section" style={{ background: `${dastsection.sectionbg}`, position: 'relative' }}>
        <div className="container">
          <div>
            <SubTitle title={dastsection.subheader} color={dastsection.textcolor} margin="1.5rem 0 0" />
            <Descriptions descriptions={dastsection.descriptions} color={dastsection.textcolor} margin="1rem 0 0" />
            <div style={{ marginTop: '1rem' }}>
              <a href={contactus.buttonlink}>
                <button>Call us &#10230;</button>
              </a>
            </div>
            <div className="hero-image" style={{ width: 200, position: 'absolute', bottom: -148, right: 0 }}>
              <PreviewCompatibleImage imageInfo={{ image: '/img/Cypress-Data-Defense-Website-Red-Alien-9.png' }} />
            </div>
          </div>
        </div>
      </section>
      <section className="section custom-section" style={{ background: `${rvsection.sectionbg}` }}>
        <div className="container">
          <div style={{ textAlign: 'center' }}>
            <SubTitle title={rvsection.subheader} color="#fff" />
          </div>
          <Descriptions descriptions={rvsection.sidedescriptions} color={rvsection.textcolor} margin="1rem 0 0" />
          <ul className="rvsection_list">
            {[
              'Dynamic application security testing',
              'Manual penetration testing',
              'Secure source code review',
              'Expert advice on how to protect your web applications for identified security vulnerabilities'
            ].map((el, i) => <li key={i}>{el}</li>)}
          </ul>
          <Descriptions descriptions={rvsection.descriptions} color={rvsection.textcolor} margin="1rem 0 0" />
          <div style={{ marginTop: '1rem' }}>
            <a href={contactus.buttonlink}>
              <button className="btn-light">Secure Your Applications Today &#10230;</button>
            </a>
          </div>
        </div>
      </section>
      <Brands />
      <WhyChooseUs
        title="With our web application penetration testing services, you can rest assured that your apps are secure."
        list={[{
          title: 'We are security experts who train others.',
          para: 'Our application security specialists regularly instruct for SANS. We teach developers and organizations how to properly perform application penetration testing'
        }, {
          title: 'We are all developers and we understand code.',
          para: 'We aren’t only experts in security, we also know how applications are (and SHOULD be) built. We can leverage this knowledge to provide the most thorough security reviews of your web applications.',
        }]} />
      <Testimonial />
      <CustomFooter data={{
        title: 'Want to Boost the Security of Your Web Applications?',
        subtitle: '',
        para: [
          'We believe that ensuring security is synonymous with delivering quality. It’s time to have our security experts ensure that your web applications are secure.'
        ],
        linkTitle: 'Talk to Our Experts Today'
      }} />
    </div>
  )

WebDynamicPenetrationTestingPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  wpptsection: PropTypes.object,
  dastsection: PropTypes.object,
  mpsection: PropTypes.object,
  rvsection: PropTypes.object,
  rrsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const WebDynamicPenetrationTestingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <WebDynamicPenetrationTestingPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        wpptsection={frontmatter.wpptsection}
        dastsection={frontmatter.dastsection}
        mpsection={frontmatter.mpsection}
        rvsection={frontmatter.rvsection}
        rrsection={frontmatter.rrsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

WebDynamicPenetrationTestingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default WebDynamicPenetrationTestingPage

export const pageQuery = graphql`
  query WebDynamicPenetrationTestingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "web-dynamic-penetration-testing" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        wpptsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        dastsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
        }
        mpsection {
          title
          secondtitle
          subheader
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        rvsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          subheader2
          descriptions
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        rrsection {
          title
          secondtitle
          subheader
          descriptions
          buttontxt
          buttonlink
          buttoncolor
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
